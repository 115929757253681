.align-start .element {
	scroll-snap-align: start;
}

.align-end .element {
	scroll-snap-align: end;
}

.align-center .element {
	scroll-snap-align: center;
}

.align-none .element {
	scroll-snap-align: none;
}


.no-support {
	text-align: center;
	padding: 5px;
	width: 450px;
	margin-left: auto;
	margin-right: auto;
	background: #ee6620;
	color: #fff;
	font-size: 16px;
}

@supports(scroll-snap-type: both mandatory) {
	.no-support {
		display: none;
	}
}

.example-wrapper {
	margin-left: auto;
	margin-right: auto;
}

.container {
	margin-left: auto;
	margin-right: auto;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.element {
	width: "100%";
	scroll-snap-stop: always;
	display: flex;
	position: relative;
}

.x-mandatory {
	scroll-snap-type: x mandatory;
}

.y-mandatory {
	scroll-snap-type: y mandatory;
}