*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}

.rego-tab>div {
	display: flex;
}

.text-max-1-line {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.text-max-2-lines {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.text-max-4-lines {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}